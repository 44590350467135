$mobileBreakpoint: 1000px;

:root {
  --bodyMarginTop: 40px;
  --bodyMargin: 24px;

  @media screen and (max-width: $mobileBreakpoint) {
    --bodyMarginTop: 24px;
    --bodyMargin: 12px;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  letter-spacing: 0;
}

::selection {
  color: white;
  background-color: black;
}

.layout {
  display: flex;
  background: #fff;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  padding: 0 var(--bodyMargin);
  justify-content: flex-start;
  font-size: 24px;
  line-height: 0.75;
  z-index: 999;
  @media screen and (max-width: $mobileBreakpoint) {
    font-size: 18px;
  }

  .title,
  .info-link {
    width: 20%;
    @media screen and (max-width: $mobileBreakpoint) {
      width: 40%;
    }
    font-size: inherit;
    span {
      cursor: pointer;
      &:hover {
        color: #aaa;
      }
    }
  }
  .info-link {
    @media screen and (max-width: $mobileBreakpoint) {
      display: none;
    }
  }
  .code {
    width: 40%;
    flex-wrap: nowrap;
    text-align: right;
    flex-grow: 1;
    direction: rtl;
    white-space: nowrap;
  }
}

.logoViewer {
  canvas {
    width: 100vw;
    height: 100vh;
    height: calc(100% - env(safe-area-inset-bottom));
  }
}

.info {
  top: 23px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  font-size: 18px;
  opacity: 0;
  pointer-events: none;

  --blockWidth: calc((100vw - var(--bodyMargin) * 2) / 5 * 3);

  @media screen and (max-width: $mobileBreakpoint) {
    font-size: 18px;
    --blockWidth: calc((100vw - var(--bodyMargin) * 2));
  }

  .main {
    margin-top: -23px;
    width: var(--blockWidth);
    line-height: 1em;

    p {
      margin-bottom: 1em;
      center {
        margin-bottom: 0;
      }
    }
  }

  .links {
    display: flex;
    width: var(--blockWidth);
    margin-bottom: var(--bodyMargin);
    @media screen and (max-width: $mobileBreakpoint) {
      display: block;
    }

    .col {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;

      a {
        small {
          display: block;
          line-height: 24px;
          font-size: 12px;
          @media screen and (max-width: $mobileBreakpoint) {
            font-size: 18px;
            line-height: 1em;
            text-align: center;
          }
        }
        span {
          @media screen and (max-width: $mobileBreakpoint) {
            display: none;
          }
        }
        color: black;
        text-decoration: none;
      }
    }
  }
}
.mobile {
  display: hidden;
}
.desktop {
  display: block;
}

@media screen and (max-width: $mobileBreakpoint) {
  .mobile {
    display: block;
  }
  .desktop {
    display: hidden;
  }
}
